const menu = [
  {
    id: 'sub1',
    label: '工作台',
    path: '/WorkManagement',
  },
  {
    id: 'sub2',
    label: '通讯录',
    path: '/AddressBook',
  },
  {
    id: 'sub3',
    label: '产品中心',
    path: '/ProductCentre',
  },
  {
    id: 'sub4',
    label: '我的应用',
    path: '/FormManagement',
  },
  {
    id: 'sub5',
    label: '企业管理',
    path: '/EnterpriseManagement',
  },
]

function getMenuIdByPath (path) {
  if (menu.find(item => item.path === path) === undefined) {
    return path
  } else {
    return menu.find(item => item.path === path).id
  }
}

export {
  menu,
  getMenuIdByPath,
}
