// 通知消息弹框
// sle
<template>
  <div id="MessageViews">
    <a-modal
      v-model:visible="messageView.visible"
      :title="messageView.title"
      ok-text="确定"
      cancel-text="取消"
      :maskClosable="false"
      @cancel="messageCancel"
      @ok="messageCancel"
      :width="800"
    >
      <a-spin :spinning="messageView.spinning">
        <a-list item-layout="vertical" :data-source="messageView.list">
          <template #renderItem="{ item }">
            <a-list-item @click="messageItemClick(item)">
              <template #extra>
                <div
                  class="flex-column align-end justify-around"
                  style="height: 100%"
                >
                  <div>{{ item.creationTime }}</div>
                  <div :style="{ color: item.read ? '' : 'red' }">
                    {{ item.read ? "已读" : "未读" }}
                  </div>
                </div>
              </template>
              <a-list-item-meta>
                <template #title>
                  {{ item.publisherName }}：{{ item.title }}
                  <div class="url">
                    <span class="detail">{{ item.detail }}</span>
                  </div>
                  <div style="margin-top:4px">
                    <a class="herf" v-if="item.url" :href="item.url"
                      >点击跳转处理</a
                    >
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-spin>
      <a-pagination
        v-model:current="messageView.current"
        v-model:pageSize="messageView.pageSize"
        hideOnSinglePage
        :total="messageView.total"
        @change="paginationChange"
      />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['editInvoiceBefore'],
  data () {
    return {
      confirmLoading: false,
      messageView: {
        visible: false,
        title: '', // 弹窗名称
        notRead: 0, // 未读数
        type: 0, // 当前显示的通知类型
        pageSize: 5, // 当前页显示数量
        current: 1, // 当前所在页
        total: 0, // 当前消息类型总数
        list: [], // 列表
        spinning: false, // 是否正在加载
      }, // 通知部分
    }
  },
  created () {
    this.getUserMessageCollect()
  },
  methods: {
    // 获取未读数量
    getUserMessageCollect () {
      api
        .get('/api/app/message/user-message-not-read')
        .then(res => {
          const obj = res.data
          this.messageView.notRead = obj.notReading
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 系统通知、任务通知按钮点击事件，呼出通知列表界面
    openInfo () {
      this.messageView.title = '系统消息'
      this.messageView.visible = true
      this.messageView.type = 0
      this.messageView.current = 1
      this.getMessageList(this.messageView.type, this.messageView.pageSize, 1)
    },
    // 获取通知列表
    getMessageList (type, size, current) {
      this.messageView.spinning = true
      api
        .get('/api/app/message/message-list', {
          params: {
            Type: type,
            PageSize: size,
            Pagination: current,
            WithExternal: true,
          },
        })
        .then(({ data }) => {
          this.messageView.spinning = false
          this.messageView.total = data.pageCount
          this.messageView.list = data.list
        })
        .catch(err => {
          this.messageView.spinning = false
          console.log(err)
        })
    },
    // 通知列表页面关闭按钮
    messageCancel () {
      this.messageView.spinning = false
      this.messageView.visible = false
      this.messageView.total = 0
      this.messageView.list = []
    },
    // 通知项点击事件
    messageItemClick (item) {
      if (item.read) {
        return
      }
      const id = item.id
      for (let i = 0; i < this.messageView.list.length; i++) {
        const item = this.messageView.list[i]
        if (item.id === id) {
          this.messageView.notRead = this.messageView.notRead - 1
          this.$emit('messageItem', this.messageView.notRead)
          this.messageView.list[i].read = true
          api
            .post('/api/app/message/message-auxiliary', {
              messageId: id,
              messageType: item.messageType,
              operation: 1,
            })
            .then(res => {
            })
            .catch(err => {
              console.log(err)
            })
          break
        }
      }
    },
    // 消息弹出页，页码变换事件
    paginationChange (page, pageSize) {
      this.getMessageList(this.messageView.type, pageSize, page)
    },
  },
})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";
.url {
  margin-top: 10px;
  font-size: 14px;
  .detail {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.45);
  }
}
.herf {
  margin-top: 10px;
  font-size: 14px;
}
</style>
