import { useRouter } from 'vue-router'
import { getMenuIdByPath } from './../router/menu'

export function getMenu (submenu) {
  const router = useRouter()
  const routes = router.options.routes
  for (const level1 of routes) {
    if (level1.children) {
      for (const level2 of level1.children) {
        if (level2.children) {
          for (const level3 of level2.children) {
            if (level3.path === submenu) {
              return getMenuIdByPath(level2.path)
            }
          }
        }
      }
    }
  }
}
