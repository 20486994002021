// 用户下拉菜单
// sle
<template>
  <a-dropdown placement="bottomRight">
    <div class="rightIcon flex-centent">
      <!-- <UserOutlined class="icon" /> -->
      <div v-if="!avatar.avatarUrl" class="userImg">
        <!-- {{ name }} -->
        <!-- 无头像就用默认头像 -->
        <img src="@/assets/defaultHead.svg" />
      </div>
      <div v-if="avatar.avatarUrl" class="userImg">
        <img :src="avatar.avatarUrl" />
      </div>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item key="1" class="userMenuItem" @click="personClick()"
          >个人账号</a-menu-item
        >
        <a-menu-item key="2" class="userMenuItem" @click="zhiWeiYunAccess()">
          <a>智维云</a>
        </a-menu-item>
        <a-menu-item key="3" class="userMenuItem" @click="weiWuLianAccess()">
          <a>威互联</a>
        </a-menu-item>
        <a-menu-item key="4" class="userMenuItem" @click="exitRegister()"
          >退出</a-menu-item
        >
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
import { defineComponent } from 'vue'
import { getPartyLoginParam } from '@/api/account/AccountAPI'
// import { UserOutlined } from '@ant-design/icons-vue'
import { getZwyUrl, getWhlUrl } from '@/api/config'
import { createLocalStorageMock } from '@/utils/tool'
export default defineComponent({
  emits: ['clearSelectKey'],
  props: {
    avatarData: {
      type: Object,
    },
  },
  components: {
    // UserOutlined,
  },
  data () {
    return {
      name: '',
      arry: [],
      avatar: {},
      isTenantCreator: false,
    }
  },
  computed: {
    // 从vuex中获取token
    getToken () {
      return this.$store.state.token
    },
  },
  watch: {
    avatarData (val) {
      this.avatar = val
      this.showName(val)
    },
  },
  mounted () {
    const localStorageMock = createLocalStorageMock()
    Object.defineProperty(window, 'localStorage', { value: localStorageMock, writable: true })
    window.addEventListener('setItemEventNew', this.handleLocalStorageChange)
  },
  beforeUnmount () {
    window.removeEventListener('setItemEventNew', this.handleLocalStorageChange)
  },
  methods: {
    openNotificationWithIcon (type) {
      this.$notification[type]({
        message: '温馨提示',
        description:
          '敬请期待',
      })
    },
    showName (val) {
      // this.name = val.name.substring(val.name, length - 2)
      var disName = val.name
      var disLength = disName.length
      this.name = disName.substring(disLength - 2, disLength)
      // console.log(this.name)
    },
    personClick () {
      this.$router.push({ path: '/UserManagement' })
      this.$emit('clearSelectKey')
    },
    // 访问智维云
    zhiWeiYunAccess () {
      const zwyUrl = getZwyUrl()
      if (!this.isTenantCreator) {
        window.open(zwyUrl)
        return
      }
      getPartyLoginParam(true).then(response => {
        if (!response.status || response.status !== 200) {
          throw new Error(response)
        }
        const { addressProvince, addressCity, addressRegion, address, firmName, userName, mobile, industry, timestamp, nonce, sign } = response.data
        const params = {
          addressProvince,
          addressCity,
          addressRegion,
          address,
          firmName,
          userName,
          mobile,
          industry,
          timestamp,
          nonce,
          sign,
        }
        const queryParams = Object.entries(params)
          .filter(([key, value]) => value !== '' && value !== null && value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        console.log(`智维云response data: ${JSON.stringify(response.data)}  \n智维云request params : ${queryParams}`)
        window.open(zwyUrl + '?' + queryParams)
      }).catch(err => {
        console.log(err)
      })
    },
    // 访问威互联
    weiWuLianAccess () {
      const whlUrl = getWhlUrl()
      if (!this.isTenantCreator) {
        window.open(whlUrl)
        return
      }
      getPartyLoginParam().then(response => {
        if (!response.status || response.status !== 200) {
          throw new Error(response)
        }
        const { userId, telephone, timestamp, nonce, sign } = response.data
        const params = new URLSearchParams({ userId, telephone, timestamp, nonce, sign })
        console.log(`威互联response data : ${JSON.stringify(response.data)} \n威互联response data : ${params.toString()}`)
        window.open(whlUrl + '?' + params.toString())
      }).catch(err => {
        console.log(err)
      })
    },
    exitRegister () {
      var _this = this
      _this.$confirm({
        okText: '确定',
        cancelText: '取消',
        content: <div>确认退出登录吗？</div>,
        onOk () {
          window.location.href = '/account/login'
          window.localStorage.clear()
          history.pushState(null, null, document.URL)
          // 清除cookie
          window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
          window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
          window.addEventListener(
            'popstate',
            function (e) {
              history.pushState(null, null, document.URL)
            },
            false,
          )
        },
        class: 'test',
      })
    },
    // localstorage事件监听
    handleLocalStorageChange (event) {
      const key = event.key
      if (key === 'userInfo' || key === 'tenantList') {
        const newValue = JSON.parse(event.newValue)
        const userInfo = key === 'userInfo' ? newValue : JSON.parse(localStorage.getItem('userInfo') || '{}')
        const tenantList = key === 'tenantList' ? newValue : JSON.parse(localStorage.getItem('tenantList') || '{}')
        this.isTenantCreator = userInfo && tenantList && userInfo.abpId === tenantList.creatorId
        if (key === 'userInfo') {
          this.avatar.avatarUrl = userInfo.avatarUrl
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
// .icon {
//   margin: 15px 13px;
//   font-size: 26px;
//   color: white;
//   cursor: pointer;
// }
.userImg {
  margin: 15px 13px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
