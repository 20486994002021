// 帮助下拉菜单
// sle
<template>
  <a-dropdown placement="bottomRight">
    <div class="rightIcon flex-centent">
      <!-- <QuestionCircleOutlined class="icon" /> -->
      <img class="icon" src="@/assets/icon_wentifankui.png" alt="" />
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item key="1" class="userMenuItem" @click="problemCreate()"
          >问题反馈</a-menu-item
        >
        <a-menu-item key="2" class="userMenuItem" @click="helpDocument()"
          >帮助文档</a-menu-item
        >
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
import { defineComponent } from 'vue'
import userHead from '@/assets/user.png'
// import { QuestionCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  emits: ['clearSelectKey'],
  components: {
    // QuestionCircleOutlined,
  },
  data () {
    return {
      userHead,
    }
  },
  methods: {
    problemCreate () {
      this.$router.push({ path: '/ProblemFeedback' })
      this.$emit('clearSelectKey')
    },
    helpDocument () {

    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.icon {
  margin: 15px 13px;
  width: 28px;
  height: 28px;
  color: white;
  cursor: pointer;
}
</style>
