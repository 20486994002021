/*
 * @Author: zhijian.he zhijian.he@YTHEZHIJIANPC
 * @Date: 2021-09-07 19:08:32
 * @LastEditors: zhijian.he zhijian.he@YTHEZHIJIANPC
 * @LastEditTime: 2023-02-15 17:29:05
 * @FilePath: \WPlatform\src\api\searchList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
mxy
搜索功能
*/

const menuList = [
  // 导航栏功能
  // { name: '消息', icon: 'static/tabBar/tab_icon_messages.png', url: '/pages/tabBar/message/index' },
  // { name: '通讯录', icon: 'static/tabBar/tab_icon_address.png', url: '/pages/tabBar/addressbook/index' },
  // { name: '工作台', icon: 'static/tabBar/tab_icon_work.png', url: '/pages/tabBar/workbench/index' },
  // { name: '市场', icon: 'static/tabBar/tab_icon_market.png', url: '/pages/tabBar/mall/index' },
  // { name: '我的', icon: 'static/tabBar/tab_icon_personal.png', url: '/pages/tabBar/mine/index' },
  // 工作台
  { applicationName: '资讯动态', url: '/WorkManagement/InformationAnnList' },
  // 通讯录
  { applicationName: '内部通讯录-组织', url: '/AddressBook/Department' },
  { applicationName: '内部通讯录-岗位', url: '/AddressBook/PostDuty' },
  // { applicationName: '外部通讯录', url: '/AddressBook/ExternaLinkMan' },
  { applicationName: '企业通讯录', url: '/AddressBook/CompanyPartner' },
  { applicationName: '企业通讯录-添加记录', url: '/AddressBook/CompanyPartner?page=record' },
  // { applicationName: '企业信息', icon: '/personal_center_icon_enterprise.png', url: '/mine/pages/corporate/index' },

  // 企业管理
  { applicationName: '使用详情', url: '/EnterpriseManagement/UseringDetail' },
  { applicationName: '企业信息', url: '/EnterpriseManagement/BasicInformation' },
  { applicationName: '应用管理', url: '/EnterpriseManagement/Application' },
  { applicationName: '订单信息', url: '/EnterpriseManagement/OrderInfo' },
  { applicationName: '申请记录', url: '/EnterpriseManagement/ApplyJoin' },
  { applicationName: '平台权限', url: '/EnterpriseManagement/Permission' },
  // 个人账号
  { applicationName: '个人信息', url: '/UserManagement/PersonInformation' },
  { applicationName: '我的提问', url: '/UserManagement/MyQuestions/WorkOrder' },
  { applicationName: '我的企业', url: '/UserManagement/MyEnterprise/AlreadyJionEnterprise' },
  { applicationName: '已加入企业', url: '/UserManagement/MyEnterprise/AlreadyJionEnterprise' },
  { applicationName: '加入企业', url: '/UserManagement/MyEnterprise/JionEnterprise' },
  { applicationName: '创建企业', url: '/UserManagement/MyEnterprise/CreatEnterprise' },

  // 升级续费
  { applicationName: '升级续费', url: '/UpgradeRenewal' },

  // 问题反馈
  { applicationName: '问题反馈', url: '/ProblemFeedback' },
  // { name: '订单信息', url: '/mine/pages/orders/index' },
  // { name: '帮助中心', url: '/mine/pages/otherfunctions/helpcenter' },
  // { name: '工单反馈', url: '/mine/pages/otherfunctions/workfeedback' },
  // { name: '设置', url: '/mine/pages/otherfunctions/setting' },
  // { name: '隐私设置', url: '/mine/pages/privacysetting/index' },
  // { name: '安全设置', url: '/mine/pages/securitySettings/index' },
  // { name: '更换手机号', url: '/mine/pages/securitySettings/updatePhone' },
  // { name: '更换邮箱号', url: '/mine/pages/securitySettings/updateEmail' },
  // { name: '修改密码', url: '/mine/pages/profile/changepassword' },
  // { name: '个人信息', url: '/mine/pages/profile/index' },
  // { name: '企业等级', url: '/mine/pages/myservices/enterpriselevel' },
  // // 消息
  // { name: '系统通知', url: '/workbench/pages/message/notices' },
  // { name: '待办事宜', url: '/pages/ibps/webview' },
  // // 通讯录
  // { name: '新的好友', url: '/addressbook/pages/newfriends/index' },
  // { name: '我的好友', url: '/addressbook/pages/myfriends/index' },
  // // 显示企业名称动态
  // { name: '企业名称', url: '/addressbook/pages/enterprise/index' },
  // { name: '加入申请', url: '/addressbook/pages/enterprise/joinapply/index' },
  // { name: '添加成员', url: '/addressbook/pages/enterprise/invitationmembers/index' },
  // { name: '二维码邀请', url: '/addressbook/pages/enterprise/invitationmembers/invitationmemberbycode/index' },
  // { name: '邮箱添加', url: '/addressbook/pages/enterprise/invitationmembers/invitationmemberbyemail/index' },
  // { name: '外部联系人', url: '/addressbook/pages/externalcontact/index' },
  // { name: '添加好友', url: '/addressbook/pages/searchfriend/index' },
  // // 工作台
  // { name: '', url: '/workbench/pages/myapplications/moreapps' }, // 工作台-更多应用
  // { name: '精选专区', url: '/workbench/pages/myapplications/appmall' },
  // { name: '产品详情', url: '/workbench/pages/myapplications/appdetail' },
  // { name: '切换企业', url: '/workbench/pages/usercompany/chgcompany' },
  // { name: '加入/创建', url: '/workbench/pages/usercompany/joincompany' },
  // { name: '咨询信息', url: '/workbench/pages/information/index' },
]

// 根据菜单名称获取菜单 sle 2021年8月11日15:12:29  Add
export const searchMenuListByName = (name) => {
  const result = []
  menuList.map((item) => {
    if (item.applicationName.match(name)) {
      result.push(item)
    }
  })
  return result
}
