// 企业下拉
// sle
<template>
  <a-dropdown
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    placement="bottomCenter"
  >
    <div class="rightIcon flex-row align-center">
      <div class="logo">
        <!-- <img v-if="obj.enterpriseLogo" :src="obj.enterpriseLogo" alt="头像" /> -->
        <div v-if="obj.enterpriseLogo" class="enterpriseLogo">
          <img :src="obj.enterpriseLogo" alt="头像" />
        </div>

        <img
          v-if="!obj.enterpriseLogo"
          src="@/assets/icon_company_weishangchang-1.svg"
          class="logo"
        />
      </div>
      <div class="name" :title="obj.enterpriseName">
        <div class="enterpriseName">{{ obj.enterpriseName }}</div>
        <div class="flex align-center">
          <img
            v-if="obj.proof"
            src="@/assets/iconlibrary_icon5.png"
            class="proof"
          />
          <div class="enterpriseProofName">{{ obj.enterpriseProofName }}</div>
        </div>
      </div>
      <CaretDownOutlined
        v-if="obj.enterpriseName"
        style="color: #fff; font-size: 16px; margin-top: 8px"
      />
    </div>

    <template #overlay>
      <a-menu class="menu">
        <div class="flex-row justify-between dropdown_menu">
          <div class="title">切换企业</div>
          <a
            class="create_title"
            href="/UserManagement/MyEnterprise/CreatEnterprise"
            >创建</a
          >
        </div>
        <div class="select">
          <div
            :key="index"
            v-for="(item, index) in obj.tenantList"
            @click="item.isLookUp !== true ? changeTenant(item) : ''"
            :class="
              item.isLookUp === true
                ? 'items item flex-row justify-between align-center'
                : 'item flex-row justify-between align-center'
            "
          >
            <div class="flex-column">
              <span class="name" :title="item.enterpriseName">{{
                item.enterpriseName
              }}</span>
              <span v-if="item.isCreator">我创建的</span>
              <span v-if="!item.isCreator">我加入的</span>
            </div>

            <div class="now" v-if="item.isLookUp">当前企业</div>
          </div>
        </div>
        <div class="trial item" @click="demoClick">试用体验</div>
        <jion-demo ref="message" />
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>

import { defineComponent } from 'vue'
import api from '@/api/API'
import { CaretDownOutlined } from '@ant-design/icons-vue'
import { getTenantList } from '@/api/IdentityAPI'
import jionDemo from '@/components/jionDemo'

export default defineComponent({
  emits: ['clearSelectKey'],
  components: {
    CaretDownOutlined,
    'jion-demo': jionDemo,
  },
  data () {
    return {
      obj: {},
      enterpriseProofName: '',
    }
  },
  created () {
    getTenantList().then(data => {
      this.obj = data
      data.tenantList.forEach(element => {
        if (element.isLookUp === true) {
          this.enterpriseProofName = element.enterpriseProofName
        }
      })
      localStorage.setItem('tenantList', JSON.stringify(data))
    })
  },
  mounted () {
    window.addEventListener('setItemEvent', (e) => {
      if (e.key === 'tenantList') {
        const data = JSON.parse(e.newValue)
        this.obj = Object.assign({}, data)
        data.tenantList.forEach(element => {
          if (element.isLookUp === true) {
            this.enterpriseProofName = element.enterpriseProofName
          }
        })
      }
    })
  },
  methods: {
    demoClick () {
      this.$refs.message.openInfo()
    },
    // 切换企业
    changeTenant (e) {
      this.$message.loading('正在切换企业')
      api
        .post('/api/app/account/switch-tenant/{' + e.tenantId + '}')
        .then(({ data }) => {
          if (data.isBan) {
            this.$message.error('当前企业已被禁用，无法切换')
            return
          }
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          var url = window.location.href
          window.location.href = url
          // location.reload()
          window.location.href = '/WorkManagement/WorkBench'
        })
        .catch(err => {
          // this.$message.error('切换租户失败')
          console.log(err)
        })
      this.$emit('clearSelectKey')
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.rightIcon {
  height: 100%;
  width: 200px;
  cursor: pointer;
  .logo {
    width: 46px;
    height: 46px;
    overflow: hidden;
    .enterpriseLogo {
      width: 46px;
      position: relative;
      height: 100%;
      border-radius: 8%;
      // overflow: hidden;
      // text-align: center;
      background-color: #fff;
      img {
        width: 100%;
        max-height: 46px;
        max-width: 46px;
        object-fit: cover;
        height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    img {
      width: 46px;
      height: 46px;
      margin: auto;
    }
  }
  .name {
    margin: 0 8px 0 15px;
    color: white;
    font-size: 16px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .enterpriseName {
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .proof {
      max-height: 25px;
      max-width: 25px;
      margin-right: 5px;
    }
  }
  .enterpriseProofName {
    font-size: 12px;
  }
}
.trial {
  text-align: center;
  color: #134ccf;
  cursor: pointer;
}
.menu {
  width: 300px;
  position: fixed;
  z-index: 9999;
  max-height: 84%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  .title {
    font-size: 18px;
    padding: 10px 15px 10px 15px;
  }
  .create_title {
    font-size: 16px;
    padding: 10px 15px 10px 15px;
    color: #134ccf;
  }
  .item {
    // height: 200px;
    border-top: 1px solid #f2f1ef;
    padding: 10px 15px;
    cursor: pointer;
    .now {
      padding: 3px 5px;
      background: #e7ecf8;
      color: #004dca;
    }
    .switch {
      width: 66px;
      height: 28px;
      background-color: #134ccf;
    }
    .name {
      display: inline-block;
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .item:hover {
    background-color: #f2f2f2;
  }
  .items {
    cursor: not-allowed;
  }
}
.menu::-webkit-scrollbar {
  width: 10px;
  /**/
}
.menu::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.menu::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.menu::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
::v-deep(.ant-btn:hover, .ant-btn:focus) {
  border-color: #134ccf;
  opacity: 0.7;
  color: #fff;
}
</style>
