// 二维码
// sle
<template>
  <a-dropdown placement="bottomCenter">
    <div class="rightIcon flex-centent">
      <img class="icon" src="@/assets/icon_fangwen.png" alt="" />
    </div>
    <template #overlay>
      <div class="main flex-column">
        <div class="title flex-centent">
          <span class="text">你可以通过以下两种方式访问</span>
        </div>
        <div class="bottom flex-row justify-around">
          <div class="left flex-column justify-around align-around">
            <img src="@/assets/ewm2.png" alt="威智造小程序" />
            <div class="text flex-centent">威智造小程序</div>
          </div>
          <div class="right flex-column justify-around align-around">
            <img class="qcCode" :src="officialAccounts" alt="威智造公众号" />
            <div class="text flex-centent">威智造公众号</div>
          </div>
        </div>
      </div>
    </template>
  </a-dropdown>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
      // miniProgram: 'https://wservice.yaweicloud.com/iconfont/39fdd8e1-f927-3be1-af35-f929a70cbf6e/威智造小程序.png', // 小程序二维码
      // miniProgram: 'https://wservice.yaweicloud.com/iconfont/39fed625-3bef-2fee-b82e-0faa1a97b0a6/威智造2.jpg', // 小程序二维码
      officialAccounts: 'https://wservice.yaweicloud.com/iconfont/39feda87-b2a4-2201-30c7-97c8d13543cd/威平台公众号.png', // 威智造公众号
    }
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.icon {
  margin: 15px 13px;
  width: 28px;
  height: 28px;
  color: white;
  cursor: pointer;
}

.main {
  box-shadow: 2px 2px 2px 2px #bebdbd;
  border-radius: 5px;
  height: 210px;
  width: 400px;
  background: #ffff;
  .title {
    height: 45px;
    width: 100%;
  }
  .bottom {
    height: 154px;
    width: 100%;
    .qcCode {
      height: 129px;
      width: 129px;
    }
  }
}
</style>
