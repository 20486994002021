<template>
  <a-layout class="home">
    <!-- 头部导航栏 -->
    <div class="header flex-row justify-center">
      <div class="header_title">
        <div class="roof flex-row justify-between">
          <div class="flex-row">
            <div>
              <tenantVDropDown
                @clearSelectKey="clearSelectKey"
                @viewInitialize="viewInitialize"
              />
            </div>
            <!-- 一级菜单导航栏 -->
            <a-menu
              theme="dark"
              mode="horizontal"
              v-model:selectedKeys="selectedKeys1"
              class="navigationBar"
              :style="{ height: top, lineHeight: top }"
            >
              <a-menu-item v-for="item in menuRef" :key="item.id">
                <router-link :to="item.path">{{ item.label }}</router-link>
              </a-menu-item>
            </a-menu>
          </div>
          <div>
            <a-auto-complete
              v-model:value="value"
              class="inputSearch"
              size="large"
              @search="onSearch"
            >
              <!-- <template #dataSource> -->
              <template #options>
                <a-select-option
                  v-for="item in menuList"
                  :key="item.applicationName"
                  :title="item.applicationName"
                >
                  <div style="color: #40a9ff" @click="gout(item)">
                    <span v-if="item.isFromCenter === true"> 产品中心 — </span>
                    <span v-if="item.isFromCenter === false"> 我的应用 — </span>
                    <span>
                      {{ item.applicationName }}
                    </span>
                  </div>
                </a-select-option>
              </template>
              <a-input-search
                size="large"
                placeholder="搜索你的关键字"
                enterButton
                allowClear
              ></a-input-search>
            </a-auto-complete>
          </div>
          <!-- 右侧按钮 -->
          <div class="user flex-row">
            <div class="rightIcon flex-centent" @click="messageClick">
              <a-badge :count="notRead">
                <!-- <BellOutlined class="icon" /> -->
                <img class="icon" src="@/assets/icon_tongzhi.png" alt="" />
              </a-badge>
            </div>
            <qrCodeSuspend />
            <helpDropDown @clearSelectKey="clearSelectKey" />
            <userDropDown
              @clearSelectKey="clearSelectKey"
              :avatarData="avatarUrl"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="body flex-row justify-center"
      :style="{
        width: width,
        minHeight: pageHeight,
      }"
    >
      <router-view />
    </div>

    <a-layout-footer :style="{ textAlign: 'center' }">
      ©2021 江苏亚威智能系统有限公司
    </a-layout-footer>
  </a-layout>
  <a-modal
    v-model:visible="visible"
    :title="title"
    ok-text="确认"
    :maskClosable="false"
    cancel-text="取消"
    footer
    :closable="false"
  >
    <a-spin :spinning="spinning">
      <div class="refresh">{{ content }}</div>
      <div class="handle_logout">
        <a-button type="primary" @click="handleLogout"> 确定 </a-button>
      </div>
    </a-spin>
  </a-modal>
  <!-- 应用推荐弹框 -->
  <a-modal
    :visible="tenantVisible"
    :width="tenantWidth"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @cancel="tenantFirst"
    :confirmLoading="confirmLoading"
    @ok="tenantFirstOK"
  >
    <div class="flex-column align-center">
      <div><img src="@/assets/yingyongchahua.png" alt="" /></div>
      <div class="describe">优质应用推荐，快去添加吧！</div>
    </div>
  </a-modal>
  <!-- 数据库创建完成弹框 -->
  <a-modal
    title="提示"
    :width="refreshWidth"
    footer
    :visible="refreshVisible"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    :closable="false"
  >
    <div class="refresh">数据库已创建完成，正在刷新页面</div>
    <!-- <a-button @click="refreshOk" class="signOut">确认</a-button> -->
  </a-modal>
  <!-- 日志更新弹框 -->
  <a-modal
    :visible="versionUpdateVisible"
    :confirm-loading="confirmLoading"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @cancel="versionUpdateOK"
    @ok="versionUpdateOK"
  >
    <div class="flex-column align-center">
      <div><img src="@/assets/card_banbengengxin.png" alt="" /></div>
      <div class="describe">版本更新</div>
    </div>
    <div v-html="detail"></div>
  </a-modal>
  <message-views ref="message" @messageItem="messageItem" />
</template>
<script>
// import { BellOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { menu } from '@/router/menu'
import * as signalR from '@microsoft/signalr'
import api from '@/api/API'
import userDropDown from '../components/userDropDown.vue'
import helpDropDown from '../components/helpDropDown.vue'
import tenantVDropDown from '../components/tenantVDropDown.vue'
import MessageViews from '../components/MessageViews.vue'
import qrCodeSuspend from '../components/qrCodeSuspend.vue'
import { getMenu } from './../utils/getMenu'
import { getEnvVars } from '@/api/config'
import { getProfileDetail } from '@/api/IdentityAPI'
import { searchMenuListByName } from '@/api/searchList.js'

export default {
  components: {
    userDropDown,
    // BellOutlined,
    helpDropDown,
    tenantVDropDown,
    qrCodeSuspend,
    'message-views': MessageViews,
  },
  data () {
    return {
      spinning: false,
      inputSearch: {},
      value: '',
      visible: false,
      // visible1: false,
      versionUpdateVisible: false,
      tenantWidth: '300px',
      refreshWidth: '400px',
      // isFirstEntrance: null,
      tenantVisible: false,
      refreshVisible: false,
      notRead: 0,
      top: '', // 导航栏高度
      pageHeight: '', // 中间页面部分高度
      width: '', // 总页面宽度
      leftWidth: '', // 左侧菜单栏宽度
      selectedKeys1: [], // 当前选中的顶部菜单
      menuRef: [], // 菜单列表（顶部显示）
      content: '',
      chatList: [
        {
          id: '1',
          name: '1号',
          unread: 1,
        },
      ], // 当前聊天窗口
      colorTheme: ['#fff'], // 颜色主题
      connection: null, // signalr连接
      title: '切换企业',
      tenantsList: {},
      tenantListLength: 0,
      avatarUrl: {},
      detail: '',
      menuList: [], // 菜单列表
      confirmLoading: false,
      menuListData: [],
      menu: [],
    }
  },
  created () {
    this.versionUpdate()
    this.viewInitialize()
    this.signalRConnection()
    this.getUserMessageCollect()
    // this.navBarItem.forEach((item, index) => {
    //   // 检测当前路由路径  然后跳转到对应的item
    //   if (decodeURI(this.$route.path).search(item.path) !== -1) {
    //     this.activeNum = index
    //   }
    // })
    // getProfileDetail().then(data => {
    //   this.isFirstEntrance = data.isFirstEntrance
    //   if (this.isFirstEntrance === true) {
    //     this.showConfirm()
    //   }
    // })
  },
  mounted () {
    if (localStorage.getItem('tenant') === 'null') {
      window.location.href = '/account/create'
      return
    }
    this.signalROn()
    // 接到通知信息
    this.connection.onclose(() => {
      // console.log('连接断开,5秒后重连')
      setTimeout(() => {
        this.signalRConnection()
        this.signalROn()
      }, 5000)
    })
  },
  methods: {
    // signalR监听频道
    signalROn () {
      var _this = this
      this.connection.on('broadcastMessage', function (message) {
        const obj = JSON.parse(message)
        if (obj.MessageOperating === 1 && obj.TenantId === localStorage.getItem('tenant')) {
          const status = obj.Detail.search('企业解散') !== -1
          if (status === true) {
            _this.content = '您已解散当前企业,需重新登录'
          } else {
            _this.content = '您已被移除当前企业,需重新登录'
          }
          _this.visible = true
          this.spinning = true
          window.localStorage.clear()
          // 清除cookie
          window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
          window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
          history.pushState(null, null, document.URL)
          window.addEventListener(
            'popstate',
            function (e) {
              history.pushState(null, null, document.URL)
            },
            false,
          )
        }
        if (obj.MessageOperating === 12) {
          _this.refreshVisible = true
          const token = JSON.parse(localStorage.getItem('token'))
          const obj = {
            refreshToken: token.refresh_token,
          }
          api
            .post('/api/app/account/refresh', obj)
            .then(({ data }) => {
              localStorage.setItem('token', '')
              const tokenData = JSON.stringify(data)
              _this.$store.dispatch('account/setToken', tokenData)
              setTimeout(() => {
                location.reload()
              }, 1000)
            })
            .catch(err => {
              console.log(err)
              _this.refreshVisible = false
            })
        }
        // console.log(_this.notRead, 'before')
        _this.notRead = _this.notRead + 1
        // console.log(_this.notRead, 'after')
      })
    },
    // 跳转
    gout (item) {
      if (item.isFromCenter === true) {
        window.location.href = '/ProductCentre/Detail?id=' + item.applicationId
      }
      if (item.url) {
        window.location.href = item.url
      }
      this.spinning = true
      api
        .get('/api/app/sys-application-on/application-can-be-use', {
          params: {
            id: item.applicationId,
          },
        })
        .then(({ data }) => {
          api
            .get('/api/app/sys-application-on/application-on-id', {
              params: {
                id: item.applicationId,
              },
            })
            .then(({ data }) => {
              const routeData = this.$router.resolve({ path: '/ApplicationDetails', query: { id: data.id } })
              window.open(routeData.href, '_blank')
              this.spinning = false
            })
            .catch(err => {
              console.log(err)
              this.spinning = false
              // this.$message.error('获取失败' + err)
            })
        })
        .catch(err => {
          console.log(err)
          this.spinning = false
          // this.$message.error('获取失败' + err)
        })
    },
    handleLogout () {
      this.spinning = true
      window.location.href = '/account/login'
    },
    getProfileDetail () {
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        this.isFirstEntrance = data.isFirstEntrance
        // if (this.isFirstEntrance === true) {
        //   this.showConfirm()
        // }
      })
    },
    versionUpdate () {
      api
        .get('/api/app/version-update/version-app')
        .then(({ data }) => {
          if (data.id) {
            this.versionUpdateVisible = true
            this.detail = data.detail
          } else {
            this.getProfileDetail()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    versionUpdateOK () {
      this.confirmLoading = true
      api
        .post('/api/app/user-update/input-user-app')
        .then(({ data }) => {
          this.versionUpdateVisible = false
          this.confirmLoading = false
        })
        .catch(err => {
          console.log(err)
          this.versionUpdateVisible = false
          this.confirmLoading = false
        })
      this.getProfileDetail()
    },
    refreshCancel () {
      this.refreshVisible = false
    },
    // showConfirm () {
    //   this.tenantVisible = true
    // },
    tenantFirst () {
      this.tenantVisible = false
      api
        .post('/api/multi-tenancy/tenants/tenant-first-entrance')
        .then(({ data }) => {
        })
        .catch(err => {
          console.log(err)
        })
    },
    tenantFirstOK () {
      this.confirmLoading = true
      this.tenantFirst()
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        if (data.isFirstEntrance === false) {
          window.location.href = '/ProductCentre/AllProduct'
        }
      })
    },
    signalRConnection () {
      const { apiUrl } = getEnvVars()
      const hubUrl = apiUrl + '/api/chat'
      // const token = JSON.parse(localStorage.getItem('token'))
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // console.log(userInfo.permissions)
      // .net core 版本中默认不会自动重连，需手动调用 withAutomaticReconnect
      const connection = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        // .withUrl(hubUrl, {
        //   accessTokenFactory: () => token.access_token,
        // })
        .withUrl(hubUrl)
        .build()

      this.connection = connection
      this.$store.connection = connection
      this.$store.dispatch('signalR/setConnection', connection)
      connection
        .start()
        .then(function () {
          // 连接后，先发送当前人信息
          const data = { channel: 'PC', userId: userInfo.id }
          connection.invoke('UserData', JSON.stringify(data))
        })
        .catch(err => console.log(err))
    },
    // 搜索信息
    onSearch () {
      // 搜索菜单
      if (this.value) {
        api
          .get('/api/app/sys-application-on/over-all-search', {
            params: {
              SearchInput: this.value,
            },
          })
          .then(({ data }) => {
            // console.log(data)
            this.menuList = data
            this.menu = searchMenuListByName(this.value)
            this.menu.forEach((item) => {
              this.menuList.push(item)
            })
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.menuList = []
        this.menu = []
      }
    },
    // 点击未读
    messageClick () {
      this.getUserMessageCollect()
      this.$refs.message.openInfo()
    },
    // 获取未读数量
    messageItem (data) {
      if (data < 0) {
        return
      }
      this.notRead = data
    },
    //  获取未读数量
    getUserMessageCollect () {
      api
        .get('/api/app/message/user-message-not-read')
        .then(res => {
          const obj = res.data
          this.notRead = obj.notReading
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 页面初始化
    viewInitialize () {
      const lw = 200 // 左侧菜单宽度
      const th = 64 // 导航栏高度
      const vh = innerHeight - 135
      this.top = th + 'px'
      this.pageHeight = vh + 'px'
      // this.width = (innerWidth - 20) + 'px'
      this.leftWidth = lw + 'px'
      this.menuRef = menu
      const path = this.$route.path
      const key = getMenu(path)
      this.selectedKeys1.push(key)
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        this.avatarUrl = data
        this.$store.dispatch('profile/setProfile', data)
        // this.isFirstEntrance = data.isFirstEntrance
        // if (this.isFirstEntrance === false) {
        //   this.showConfirm()
        // }
      })
    },
    // 清空选中key
    clearSelectKey () {
      this.selectedKeys1 = []
    },
    okCancel () {
      this.visible = false
    },
  },
}
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.home {
  // overflow: auto;
  .header {
    width: 100%;
    background: #134ccf;
    height: 64px;
    background: #134ccf;
    position: fixed;
    z-index: 100;
  }
  .header_title {
    width: 82%;
    .navigationBar {
      background: #134ccf;
    }
    .inputSearch {
      position: relative;
      top: 16px;
      left: 8px;
      width: 220px;
    }
    .user {
      z-index: 100;
      .rightIcon {
        margin: 16px 12px;
        .icon {
          cursor: pointer;
          // width: 28px;
          // height: 30px;
          // color: white;
        }
      }
    }
  }
  .body {
    margin-top: 64px;
    overflow: inherit;
    width: 100%;
  }
  .radioClickOn {
    color: white;
  }
  .describe {
    margin-top: 20px;
  }
}

.ant-layout-header {
  padding: 0px !important;
  background: #2953b2;
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
.selects {
  line-height: 100px;
  min-height: 100px;
}
.select {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  /*修改滚动条样式*/
  .title {
    font-size: 18px;
    // padding: 10px 15px 10px 15px;
  }
  .item {
    border-bottom: 1px solid #f2f1ef;
    padding: 15px;
    .now {
      padding: 3px 5px;
      background: #e7ecf8;
      color: #004dca;
    }
    .name {
      display: inline-block;
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.select::-webkit-scrollbar {
  width: 10px;
  /**/
}
.select::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.select::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.select::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
.signOut {
  margin: 20px 0;
  position: relative;
  top: 20px;
  left: 300px;
}
.refresh {
  padding: 13px 0 30px 0;
}
.handle_logout {
  position: relative;
  left: 88%;
}
::v-deep(.ant-badge-count) {
  // -webkit-transform: scale(0.8);
  position: absolute;
  left: 16px;
  min-width: 15px;
  height: 15px;
  color: #fff;
  line-height: 15px;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff;
  padding: 0;
  margin-top: 8px;
  box-sizing: border-box;
}
::v-deep(.ant-select-item-option-content) {
  a {
    width: 200px;
  }
}
::v-deep(.ant-select-single
    .ant-select-selector
    .ant-select-selection-search-input) {
  height: 34px;
}
::v-deep(.ant-input-affix-wrapper > input.ant-input) {
  font-size: 14px;
}
::v-deep(.ant-input-search-enter-button
    + .ant-input-group-addon
    .ant-input-search-button) {
  height: 34px;
}
::v-deep(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #ccc;
}
</style>
